import { fetcher } from 'fetcher!sofe';

export const getOutstandingInvoiceTerms = clientId =>
  fetcher(`/api/dashboard/invoices/overdue_ranges${clientId ? `?client_id=${clientId}` : ''}`).then(
    data => data.ranges
  );

export const getYTDRevenue = clientId =>
  fetcher(`/api/dashboard/invoices/ytd_revenue_by_month${clientId ? `?client_id=${clientId}` : ''}`).then(
    data => data.revenues
  );

export const getAvailableCredit = clientId =>
  fetcher(`/api/dashboard/invoices/outstanding${clientId ? `?client_id=${clientId}` : ''}`).then(
    data => +data.available_credit
  );
