import { fetchAsObservable, fetcher } from 'fetcher!sofe';
import { isEmpty } from 'lodash';
import { pluck } from 'rxjs/operators';

export const getAdyenOnBoardingLink = onBoardingInfo => {
  const { team_can_key_in_cards, confirm_surcharge, ...rest } = onBoardingInfo;
  return fetchAsObservable('/api/canopy_payments/account/onboarding_link', {
    method: 'POST',
    body: isEmpty(onBoardingInfo)
      ? {}
      : team_can_key_in_cards && Object.keys(onBoardingInfo).length === 1
      ? { team_can_key_in_cards }
      : { account: { ...rest }, team_can_key_in_cards, confirm_surcharge },
  }).pipe(pluck('link'));
};

export const deleteAdyenOnBoardingLink = () => {
  return fetchAsObservable('/api/canopy_payments/account/reset', {
    method: 'DELETE',
  });
};

export const getAdyenTermsAndConditions = () => {
  return fetchAsObservable('/api/canopy_payments/onboarding/terms', { responseType: 'blob' });
};

export const getPaymentsOnboardingStatus = () => {
  return fetcher('/api/canopy_payments/onboarding/status').then(response => response.status);
};

export const getUpdatedAdyenInfo = () => {
  return fetchAsObservable('/api/canopy_payments/account:update');
};

export const initiateMicroDeposit = () => {
  return fetchAsObservable('/api/canopy_payments/onboarding/microdeposit', {
    method: 'POST',
    body: {},
  });
};

export const getRemainingDepositAttempts = () => {
  return fetchAsObservable('/api/canopy_payments/onboarding/microdeposit');
};

export const verifyMicroDeposit = amount => {
  return fetchAsObservable('/api/canopy_payments/onboarding/microdeposit:verify', {
    method: 'POST',
    body: { microdeposit: { amount } },
  });
};

export const updateAmexMerchantId = data => {
  return fetchAsObservable('/api/canopy_payments/onboarding/submit_amex', {
    method: 'POST',
    body: data,
  }).pipe(pluck('status'));
};

export const toggleAmexOpt = optStatus => {
  return fetchAsObservable(`/api/canopy_payments/amex_opt?opt_out=${optStatus}`, {
    method: 'PATCH',
  });
};

export const toggleSurcharge = checked => {
  return fetchAsObservable('/api/canopy_payments/surcharge', {
    method: 'PATCH',
    body: { activate: checked },
  });
};
