import { DateTime } from 'luxon';
import canopyUrls from 'canopy-urls!sofe';

export const isProd = () => canopyUrls.getEnvironment() === canopyUrls.PRODUCTION_ENVIRONMENT;

export const defaultDateFormat = 'M/D/YYYY';

export const luxonDefaultDateFormat = 'M/d/yyyy';

export const ISODateFormat = 'YYYY-MM-DD';

export const luxonISODateFormat = 'yyyy-MM-dd';

export const creditCardFormat = 'MM/YYYY';

export const luxonCreditCardFormat = 'MM/yyyy';

export const longDateFormat = 'MMM DD, YYYY';

export const pageLimitData = [
  { id: '50', name: '50' },
  { id: '100', name: '100' },
  { id: '200', name: '200' },
  { id: 'all', name: 'All' },
];

String.prototype.capitalize = function () {
  return this.replace(/\b\w/g, c => c.toUpperCase());
};

String.prototype.insert = function (index, string) {
  if (index > 0) return this.substring(0, index) + string + this.substring(index, this.length);
  else return string + this;
};

export const wholeNumberFilter = (value, allowLeadingZero = false) => {
  return value.startsWith('0') && !allowLeadingZero ? value.replace(/^0+/, '') : value.replace(/[^0-9]*/g, '');
};

//note that passing in 0 for precision will actually give infinite precision, use wholeNumberFilter for that instead.
export const decimalNumberFilter = (value, precision, allowComma = false, allowNegative = true) => {
  {
    let regExpression;
    if (allowComma && allowNegative) {
      regExpression = /[^0-9.,-]+/g;
    } else if (allowComma) {
      regExpression = /[^0-9.,]+/g;
    } else if (allowNegative) {
      regExpression = /[^0-9.-]+/g;
    } else {
      regExpression = /[^0-9.]+/g;
    }
    var clean = value.toString().replace(regExpression, '');
    const firstDecimal = clean.indexOf('.');
    if (firstDecimal >= 0) {
      //only one decimal point
      let secondDecimal = clean.indexOf('.', firstDecimal + 1);
      if (secondDecimal >= 0) {
        clean = clean.substring(0, secondDecimal);
      } else if (precision) {
        //precision
        const endIndex = Math.min(clean.length, clean.indexOf('.') + precision + 1);
        clean = clean.substring(0, endIndex);
      }
      if (allowComma) {
        let commaAfterDecimal = clean.indexOf(',', firstDecimal + 1);
        if (commaAfterDecimal >= 0) {
          clean = clean.substring(0, commaAfterDecimal);
        }
      }
    }

    if (clean === '.') return '0.';
    return clean;
  }
};

export const nameFilter = value => {
  let regex = /^[A-Za-z  ,.'-]*$/;
  let clean = regex.exec(value);
  if (!clean) {
    value = value.slice(0, -1);
  }
  return value;
};

export const isValidHttpUrl = string => {
  try {
    const newUrl = new URL(string);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const weekFilter = {
  filter_params: 'custom',
  after: DateTime.local().minus({ days: 7 }).toISODate(),
  before: DateTime.local().toISODate(),
};

export const monthFilter = {
  filter_params: 'currentMonth',
  after: DateTime.local().startOf('month').toISODate(),
  before: DateTime.local().toISODate(),
};

export const lastMonthFilter = {
  filter_params: 'lastMonth',
  after: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
  before: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
};

export const thirtyDayFilter = {
  filter_params: 'custom',
  after: DateTime.local().minus({ days: 30 }).toISODate(),
  before: DateTime.local().toISODate(),
};

export const round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const getClientIdFromUrl = () => {
  const matches = /wip?\/(\d*)/.exec(window.location.hash);
  return matches ? matches[1] : null;
};

export const getTaskIdFromUrl = () => {
  const matches = /task?\/([A-Z0-9]+)/.exec(window.location.hash);
  return matches ? matches[1] : null;
};

export const getSubtaskIdFromUrl = () => {
  const matches = /subtask=?([A-Z0-9]+)/.exec(window.location.hash);
  return matches ? matches[1] : null;
};

export const addOneDay = date => {
  return DateTime.fromISO(date)
    .plus({
      days: 1,
    })
    .toISODate();
};

export const scrolledToBottomOfElement = element => {
  if (!element) return false;

  const scrollDifference = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight);

  const tolerance = 2;

  return scrollDifference <= tolerance;
};

export function downloadFile(fileName, text, mimeType = 'text/plain') {
  const el = document.createElement('a');
  const file = new Blob([text], { type: mimeType });
  el.href = URL.createObjectURL(file);
  el.download = fileName;
  el.click();
}

export const transformItem = ({ fieldId, data }) => {
  const newObj = {};

  if (fieldId === 'date') {
    return { [fieldId]: data };
  }

  data.forEach(({ filterValue, condition, operator }) => {
    if (!newObj[condition]) {
      newObj[condition] = [];
    }

    if (!filterValue) {
      newObj[condition] = true;
    } else {
      typeof filterValue === 'object'
        ? (newObj[condition] = [...newObj[condition], ...filterValue.map(f => f.id.toString())])
        : newObj[condition].push(filterValue);
    }

    if (operator) {
      newObj.operator = operator;
    }
  });

  return { [fieldId]: newObj };
};

export const transformKeys = (obj, keyMap) => {
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = keyMap[key] || key;
    newObj[newKey] = value;
  }
  return newObj;
};

export const transformBoolFilter = (key, specialValue, data) => {
  const computedValue = data.length === 2 ? null : data.includes(specialValue) ? false : true;
  return { [key]: computedValue };
};
