import { fetcher, fetchAsObservable } from 'fetcher!sofe';
import { pluck, map } from 'rxjs/operators';
import { addOneDay } from '../billing-helpers';

export const getPayment = params => {
  return fetchAsObservable(
    `/api/payments/${params.paymentId}${params.include ? `?include=${params.include}` : ''}`
  ).pipe(pluck('payments'));
};

export const getAllPayments = (filters, clientId = null, page = 1) => {
  if (clientId && !filters.client_id) {
    filters.client_id = { filter_params: [clientId] };
  }

  return fetchAsObservable(`/api/payments:search?limit=50&page=${page}`, {
    method: 'POST',
    body: filters,
  });
};

export const getAllRefunds = (filters, clientId = null, page = 1) => {
  if (clientId && !filters.client_id) {
    filters.client_ids = { filter_params: [clientId] };
  }
  return fetchAsObservable(`/api/refunds/search?limit=50&page=${page}`, {
    method: 'POST',
    body: filters,
  });
};

export const getPaymentCSV = (filters, clientId = null, refunds) => {
  if (clientId && !filters.client_id) {
    filters.client_id = { filter_params: [clientId] };
  }

  if (refunds) {
    filters.refunds_only = true;
  }

  return fetchAsObservable(`/api/payments/export`, {
    method: 'POST',
    body: filters,
  });
};

export const getRefundCSV = (filters, clientId = null) => {
  if (clientId && !filters.client_ids) {
    filters.client_ids = { filter_params: [clientId] };
  }

  return fetchAsObservable(`/api/refunds/export`, {
    method: 'POST',
    body: filters,
  });
};

export function getPaymentFilter(type, searchParams) {
  return fetchAsObservable(`/api/payments:search-filter/${type}`, {
    method: 'POST',
    body: searchParams,
  }).pipe(
    pluck(type),
    map(values =>
      values.map(value => ({
        id: value.id ? value.id.toString() : value.name,
        label: type !== 'clients' ? value.name.capitalize() : value.name,
      }))
    )
  );
}

export function getRefundFilter(type, searchParams) {
  return fetchAsObservable(`/api/refunds/search/${type}`, {
    method: 'POST',
    body: searchParams,
  }).pipe(
    pluck(type),
    map(values =>
      values.map(value => ({
        id: value.id ? value.id.toString() : value.name,
        label: type !== 'clients' ? value.name.capitalize() : value.name,
      }))
    )
  );
}

export const createPayment = payment => {
  return fetchAsObservable('/api/payments', {
    method: 'POST',
    body: payment,
  }).pipe(pluck('payments'));
};

export const schedulePayment = scheduledPayment => {
  return fetchAsObservable('/api/payments/scheduled_one_off', {
    method: 'POST',
    body: scheduledPayment,
  });
};

export function savePayment(params, payment) {
  return fetchAsObservable(`/api/payments/${params.id}`, {
    method: 'PUT',
    body: payment,
  }).pipe(pluck('payments'));
}

export function saveScheduledPayment(params, scheduledPayment) {
  return fetchAsObservable(`/api/payments/scheduled_one_off/${params.id}`, {
    method: 'PUT',
    body: scheduledPayment,
  });
}

export function refundPayment(refundData) {
  return fetchAsObservable(`/api/refunds`, {
    method: 'POST',
    body: refundData,
  });
}

export function retryFailedRefund(refundData) {
  return fetchAsObservable(`/api/refunds/${refundData.id}`, {
    method: 'PATCH',
    body: refundData,
  });
}

// only for undeleting (undo)!
export function updatePayment(params, payment) {
  return fetchAsObservable(`/api/payments/${params.id}`, {
    method: 'PATCH',
    body: payment,
  }).pipe(pluck('payments'));
}

export function deletePayment(paymentId) {
  return fetchAsObservable(`/api/payments/${paymentId}`, {
    method: 'DELETE',
  });
}

export function deleteScheduledPayment(paymentId) {
  return fetchAsObservable(`/api/payments/scheduled_one_off/${paymentId}`, {
    method: 'DELETE',
  });
}

export function printPayment(paymentId) {
  window.open(`/api/payments/${paymentId}?isPdf=true`, '_blank');
}

export function downloadPayment(paymentId) {
  location.href = `/api/payments/${paymentId}?isPdf=true&download=true`;
}

export const getPaymentPDF = (paymentId, asBlob = false) => {
  return fetchAsObservable(`/api/payments/${paymentId}?isPdf=true`, { responseType: asBlob ? 'blob' : 'json' });
};

export function getCanopyPaymentsStatus() {
  return fetcher('/api/canopy_payments/status').then(response => response.status);
}

export function getCanopyPaymentsStatusObservable() {
  return fetchAsObservable('/api/canopy_payments/status');
}

export function getAdyenCapitalStatus() {
  return fetchAsObservable('/api/adyen_capital/status');
}

export function getPaymentReceivedData(period, start, end, clientId) {
  return fetchAsObservable(
    `/api/dashboard/payments/payments_received?period=${period}&start=${start}&end=${end}&client_id=${clientId}`
  ).pipe(pluck('result'));
}

export function getPaymentTypeData(start, end, clientId) {
  return fetchAsObservable(
    `/api/dashboard/payments/payments_received_by_type?&start=${start}&end=${end}&client_id=${clientId}`
  ).pipe(pluck('result'));
}

export function getPaymentDeposits(searchTerm = '', page = 1, limit = 50, start, end) {
  return fetchAsObservable(
    `/api/canopy_payments/deposits?start=${start}&end=${addOneDay(
      end
    )}&search_field=${searchTerm}&page=${page}&limit=${limit}`
  );
}

export const getPaymentDepositsCSV = (start, end, searchTerm = '', batchId = '') => {
  return fetchAsObservable(
    `/api/canopy_payments/deposits/export?start=${start}&end=${addOneDay(
      end
    )}&search_field=${searchTerm}&batch_id=${batchId}`
  );
};

export const getAdyenPaymentDeposits = (searchTerm = '', page = 1, limit = 50, start = '', end = '') => {
  return fetchAsObservable(
    `/api/canopy_payments/payment_deposits_dashboard?page=${page}&limit=${limit}${
      searchTerm ? `&search_field=${searchTerm}` : ''
    }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`
  );
};

export const getAdyenDepositCSV = (start, end, searchTerm = '', batchId = '') => {
  return fetchAsObservable(
    `/api/canopy_payments/payment_deposits_dashboard/csv?search_field=${searchTerm}&batch_id=${batchId}${
      start ? `&start=${start}` : ''
    }${end ? `&end=${end}` : ''}`
  );
};

export const getUpcomingPayments = (filters, clientId = null, page = 1, oneTime = true) => {
  if (clientId && !filters.client_id) {
    filters.client_name = { filter_params: [clientId] };
  }

  return fetchAsObservable(`/api/payments/recurrences:search?scheduled_only=${oneTime}&limit=50&page=${page}`, {
    method: 'POST',
    body: filters,
  });
};

export const getPaymentUpcomingCSV = (filters, clientId = null, oneTime = true) => {
  if (clientId && !filters.client_id) {
    filters.client_name = { filter_params: [clientId] };
  }

  return fetchAsObservable(`/api/payments/recurrences/export?scheduled_only=${oneTime}`, {
    method: 'POST',
    body: filters,
  });
};

export function getScheduledPaymentFilter(type, searchParams, oneTime = true) {
  return fetchAsObservable(
    `/api/${oneTime ? 'payments' : 'invoices'}/recurrences:search-filter/${type}?${
      oneTime ? 'scheduled_only' : 'with_payment_details_only'
    }=true`,
    {
      method: 'POST',
      body: searchParams,
    }
  ).pipe(
    pluck(type),
    map(values =>
      values.map(value => ({
        id: value.id ? value.id.toString() : value.name,
        label: type !== 'clients' ? value.name.capitalize() : value.name,
      }))
    )
  );
}

export const getScheduledPayment = params => {
  return fetchAsObservable(
    `/api/payments/recurrences/${params.recurrenceId}${params.include ? `?include=${params.include}` : ''}`
  ).pipe(pluck('recurrences'));
};

export const getPaymentDepositsSummary = (start, end, paymentType, page) => {
  return fetchAsObservable(
    `/api/canopy_payments/payment_deposits?start=${start}&end=${addOneDay(
      end
    )}&payment_type=${paymentType}&page=${page}`
  );
};

export const getPaymentDepositsSummaryCSV = (start, end, paymentType) => {
  return `/api/canopy_payments/payment_deposits/csv?start=${start}&end=${addOneDay(end)}&payment_type=${paymentType}`;
};

export function updatePaymentSession(payment) {
  return fetchAsObservable('/api/canopy_payments/payment_session', {
    method: 'PUT',
    body: payment,
  });
}

export function processPaymentSession(paymentSessionId, paymentData) {
  return fetchAsObservable(`api/canopy_payments/payment_session/${paymentSessionId}/process`, {
    method: 'POST',
    body: paymentData,
  });
}

export const updateCanopyPayment = (cardId, paymentData) => {
  return fetchAsObservable(`/api/card_management/${cardId}`, {
    method: 'PATCH',
    body: paymentData,
  });
};

export const deleteAdyenPaymentMethod = paymentMethodId => {
  return fetchAsObservable(`/api/card_management/${paymentMethodId}`, {
    method: 'DELETE',
  });
};

export function getSurchargeFee(paymentData) {
  return fetchAsObservable('/api/canopy_payments/calculate_fees', {
    method: 'POST',
    body: paymentData,
  });
}

export function updateSecureLinkPaymentSession(objectType, objectId, payment) {
  return fetchAsObservable(`/api/canopy_payments/payment_session/object_type/${objectType}/object_id/${objectId}`, {
    method: 'PUT',
    body: payment,
  });
}

export function processSecureLinkPaymentSession(paymentData) {
  return fetchAsObservable('/api/canopy_payments/payment_session/secure_process', {
    method: 'POST',
    body: paymentData,
  });
}
